import PropTypes from 'prop-types'
import React from 'react'
import {graphql} from 'gatsby'
require('prismjs/themes/prism-okaidia.css')

import Seo from '../components/Seo'
import Article from '../components/Article'
import Post from '../components/Post'
import {ThemeContext} from '../layouts'

const PostContentfulTemplate = ({data, pageContext}) => {
  const {
    site: {
      siteMetadata: {facebook},
    },
    contentfulPost: {
      title,
      category,
      body: {
        childMarkdownRemark: {
          excerpt,
          html: postHtml
        }
      },
      author: {
        name,
        shortBio: {
          childMarkdownRemark :{
            html: authorNote
          }
        }
      }
    },
  } = data
  
  const siteTitle = data.site.siteMetadata.title
  const {prev, next, slug, prefix} = pageContext
  const post = {
    frontmatter:{
      category,
      title,
      author: name
    },
    title,
    html: postHtml,
    fields: {
    slug,
    prefix}
  }
  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>            
            <Post
              post={post}
              next={next}
              prev={prev}
              authornote={authorNote}
              facebook={facebook}
              theme={theme}
            />
          </Article>
        )}
      </ThemeContext.Consumer>
      
      <Seo data={post} facebook={facebook} />
    </React.Fragment>
  )
}
PostContentfulTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}
export default PostContentfulTemplate
export const pageQuery = graphql`
  query ContentPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        facebook {
          appId
        }
      }
    }
    contentfulPost(slug: {eq: $slug}) {
      slug
      title
      category
      body {
        childMarkdownRemark {
          excerpt
          html
        }
      }
      author {
        name
        shortBio {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
